import React, { useState } from 'react';

const BlogPostEditor = () => {
    const [blogData, setBlogData] = useState({
        innlegg_1: {
            read_time: 2,
            author: 'Pelle Nilsen',
            published: '20.02.2023',
            content: [
                { text: 'Lorem ipsum...' },
                { link: 'https://www.google.com', link_text: 'google.com' },
            ],
        },
    });

    const [editingPostName, setEditingPostName] = useState(null);
    const [editedPostName, setEditedPostName] = useState('');

    const updateValue = (postKey, field, value) => {
        setBlogData((prevData) => ({
            ...prevData,
            [postKey]: {
                ...prevData[postKey],
                [field]: value,
            },
        }));
    };

    const updateContent = (postKey, index, field, value) => {
        setBlogData((prevData) => ({
            ...prevData,
            [postKey]: {
                ...prevData[postKey],
                content: prevData[postKey].content.map((item, i) =>
                    i === index ? { ...item, [field]: value } : item
                ),
            },
        }));
    };

    const addContentItem = (postKey, type) => {
        setBlogData((prevData) => ({
            ...prevData,
            [postKey]: {
                ...prevData[postKey],
                content: [
                    ...prevData[postKey].content,
                    type === 'text'
                        ? { text: '' }
                        : { link: '', link_text: '' },
                ],
            },
        }));
    };

    const removeContentItem = (postKey, index) => {
        setBlogData((prevData) => ({
            ...prevData,
            [postKey]: {
                ...prevData[postKey],
                content: prevData[postKey].content.filter(
                    (_, i) => i !== index
                ),
            },
        }));
    };

    const addPost = () => {
        const newPostKey = `innlegg_${Object.keys(blogData).length + 1}`;
        setBlogData((prevData) => ({
            ...prevData,
            [newPostKey]: {
                read_time: 1,
                author: '',
                published: new Date().toLocaleDateString('no-NO'),
                content: [{ text: '' }],
            },
        }));
    };

    const removePost = (postKey) => {
        setBlogData((prevData) => {
            const newData = { ...prevData };
            delete newData[postKey];
            return newData;
        });
    };

    const startEditingPostName = (postKey) => {
        setEditingPostName(postKey);
        setEditedPostName(postKey);
    };

    const finishEditingPostName = (oldKey, newKey) => {
        if (oldKey !== newKey && !blogData[newKey]) {
            setBlogData((prevData) => {
                const newData = { ...prevData };
                newData[newKey] = newData[oldKey];
                delete newData[oldKey];
                return newData;
            });
        }
        setEditingPostName(null);
    };

    const renderPost = (postKey, postData) => {
        const handlePostNameChange = (e) => {
            setEditedPostName(e.target.value);
        };

        const handlePostNameBlur = () => {
            if (editedPostName.trim() !== '' && editedPostName !== postKey) {
                finishEditingPostName(postKey, editedPostName.trim());
            } else {
                setEditingPostName(null);
            }
        };

        return (
            <div
                key={postKey}
                className='mb-6 bg-white shadow-md rounded-lg p-6'
            >
                <div className='flex items-center justify-between mb-4'>
                    {editingPostName === postKey ? (
                        <input
                            value={editedPostName}
                            onChange={handlePostNameChange}
                            onBlur={handlePostNameBlur}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handlePostNameBlur();
                                }
                            }}
                            className='font-bold text-xl border-b-2 border-blue-500 focus:outline-none'
                            autoFocus
                        />
                    ) : (
                        <h2 className='text-xl font-bold flex items-center'>
                            {postKey}
                            <button
                                onClick={() => {
                                    setEditingPostName(postKey);
                                    setEditedPostName(postKey);
                                }}
                                className='ml-2 text-blue-500 hover:text-blue-700'
                            >
                                ✏️
                            </button>
                        </h2>
                    )}
                </div>
                <div className='grid grid-cols-2 gap-4 mb-4'>
                    <div>
                        <label
                            htmlFor={`${postKey}-read-time`}
                            className='block text-sm font-medium text-gray-700 mb-1'
                        >
                            Lesetid (minutter):
                        </label>
                        <input
                            id={`${postKey}-read-time`}
                            type='number'
                            value={postData.read_time}
                            onChange={(e) =>
                                updateValue(
                                    postKey,
                                    'read_time',
                                    parseInt(e.target.value)
                                )
                            }
                            className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                        />
                    </div>
                    <div>
                        <label
                            htmlFor={`${postKey}-author`}
                            className='block text-sm font-medium text-gray-700 mb-1'
                        >
                            Forfatter:
                        </label>
                        <input
                            id={`${postKey}-author`}
                            value={postData.author}
                            onChange={(e) =>
                                updateValue(postKey, 'author', e.target.value)
                            }
                            className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                        />
                    </div>
                </div>
                <div className='mb-4'>
                    <label
                        htmlFor={`${postKey}-published`}
                        className='block text-sm font-medium text-gray-700 mb-1'
                    >
                        Publisert dato:
                    </label>
                    <input
                        id={`${postKey}-published`}
                        value={postData.published}
                        onChange={(e) =>
                            updateValue(postKey, 'published', e.target.value)
                        }
                        className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                    />
                </div>
                <div className='mb-4'>
                    <h4 className='font-semibold mb-2'>Innhold:</h4>
                    {postData.content.map((item, index) => (
                        <div key={index} className='mb-2'>
                            {item.text !== undefined ? (
                                <div className='flex items-start'>
                                    <textarea
                                        value={item.text}
                                        onChange={(e) =>
                                            updateContent(
                                                postKey,
                                                index,
                                                'text',
                                                e.target.value
                                            )
                                        }
                                        placeholder='Skriv tekst her...'
                                        className='flex-grow mr-2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                                        rows='3'
                                    />
                                    <button
                                        onClick={() =>
                                            removeContentItem(postKey, index)
                                        }
                                        className='px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500'
                                    >
                                        🗑️
                                    </button>
                                </div>
                            ) : (
                                <div className='flex items-center'>
                                    <input
                                        value={item.link}
                                        onChange={(e) =>
                                            updateContent(
                                                postKey,
                                                index,
                                                'link',
                                                e.target.value
                                            )
                                        }
                                        placeholder='Lenke URL'
                                        className='flex-grow mr-2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                                    />
                                    <input
                                        value={item.link_text}
                                        onChange={(e) =>
                                            updateContent(
                                                postKey,
                                                index,
                                                'link_text',
                                                e.target.value
                                            )
                                        }
                                        placeholder='Lenketekst'
                                        className='flex-grow mr-2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                                    />
                                    <button
                                        onClick={() =>
                                            removeContentItem(postKey, index)
                                        }
                                        className='px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500'
                                    >
                                        🗑️
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                    <div className='flex space-x-2 mt-2'>
                        <button
                            onClick={() => addContentItem(postKey, 'text')}
                            className='px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500'
                        >
                            Legg til tekst
                        </button>
                        <button
                            onClick={() => addContentItem(postKey, 'link')}
                            className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500'
                        >
                            Legg til lenke
                        </button>
                    </div>
                </div>
                <button
                    onClick={() => removePost(postKey)}
                    className='px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500'
                >
                    Slett dette innlegget
                </button>
            </div>
        );
    };

    return (
        <div className='p-4 max-w-4xl mx-auto'>
            <h1 className='text-3xl font-bold mb-6'>Blogginnlegg Redigering</h1>
            <div className='mb-6 bg-blue-100 border-l-4 border-blue-500 p-4 rounded'>
                <h2 className='font-bold text-lg mb-2'>Hjelp</h2>
                <p>
                    Her kan du opprette og redigere blogginnlegg. Hvert innlegg
                    har en lesetid, forfatter, publiseringsdato og innhold. Du
                    kan legge til tekst eller lenker i innholdet. For å endre
                    navnet på et innlegg, klikk på blyantikonet ved siden av
                    innleggsnavnet.
                </p>
            </div>
            {Object.entries(blogData).map(([key, value]) =>
                renderPost(key, value)
            )}
            <button
                onClick={addPost}
                className='px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500'
            >
                Legg til nytt innlegg
            </button>
            <div className='mt-8'>
                <h2 className='text-2xl font-semibold mb-4'>JSON-resultat:</h2>
                <pre className='bg-gray-100 p-4 rounded overflow-x-auto text-sm'>
                    {JSON.stringify(blogData, null, 2)}
                </pre>
            </div>
        </div>
    );
};

export default BlogPostEditor;
