import BlogPostEditor from "./Editor";

function App() {
    return (
        <div className='App'>
            <BlogPostEditor />
        </div>
    );
}

export default App;
